import React from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from "@fortawesome/free-solid-svg-icons";

import QRCode from "qrcode";

import HeaderBarItem from "./SectionPhoneSliderComponents/HeaderBarItem";

import { Container } from "./SectionPhoneSliderComponents/SectionPhoneSliderElement";

import subLogo from "../assets/sub-title.png";
import img1 from "../assets/carousel-img-one.png?t=20210401";
import img2 from "../assets/carousel-img-two.png?t=20210401";
import img3 from "../assets/carousel-img-three.png?t=20210401";
import iphoneFrame from "../assets/iphone-frame.png";
import qrCodePng from "../assets/qr-code.png";

let location = window.location;

function isEmpty(obj) {
  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

class SectionPhoneSlider extends React.Component {
  constructor(props){
    super(props);
    // console.log('constructor');
    this.state = {
      qrCode: qrCodePng,
      url: "https://twoacg.org/frame.html"
    }
  }

  // 都會執行 用來直接指派Props
  static getDerivedStateFromProps(props = null){
    // console.log('getDerivedStateFromProps');
    if(props){
      return props
    }else{
      return null
    }
  }

  // 初始化的最後執行
  componentDidMount(){
    let vm = this;
    // console.log('componentDidMount');
    let urlParameter = location.search;
    let theRequest = {};
    let strs = null;
    if (urlParameter.indexOf("?") !== -1) {
      let str = urlParameter.substr(1);
      strs = str.split("&");
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
    }
    if(!isEmpty(theRequest)) {
      let url = vm.state.url + "?";
      for(let key in theRequest) {
        url += key + "=" + theRequest[key] + "&";
      }
      url = url.slice(0, url.length -1);

      QRCode.toDataURL(url, {
        margin: 1,
        width: 640,
        errorCorrectionLevel: 'H'
      })
        .then(url => {
          vm.setState({
            qrCode: url
          })
        })
        .catch(err => {
          console.error(err)
        })
      vm.setState({
        url: url
      })
    }
  }

  // 更新才執行，在render之前 用來處理是否應該更新 true 要 false 不要
  shouldComponentUpdate(){
    // console.log('shouldComponentUpdate');
    return true
  }
  // 更新才執行，在render之後
  getSnapshotBeforeUpdate(){
    // console.log('getSnapshotBeforeUpdate');
    return null
  }
  // 更新的最後階段
  componentDidUpdate(){
    // console.log('componentDidUpdate');
  }

  // 被摧毀時執行
  componentWillUnmount(){
    // console.log('componentWillUnmount');
  }

  render(){
    const { state } = this;
    const { 
      navListData, 
      nowMediaQuery, 
      qrCode,
      url
    } = state;
    return (
      <>
        <HeaderBarItem navListData={ navListData } nowMediaQuery={ nowMediaQuery }/>
        <Container>
          <div className="container">
            <div className="sliderbox">
              <Carousel
                className="sliderbox_root"
                showArrows={false}
                showThumbs={false}
                autoPlay={true}
                infiniteLoop={true}
                showIndicators={false}
                showStatus={false}
              >
                <div className="sliderbox_root_card">
                  <img className="sliderbox_root_card_img" src={img1} alt="輪播圖片"/>
                </div>
                <div className="sliderbox_root_card">
                  <img className="sliderbox_root_card_img" src={img2} alt="輪播圖片"/>
                </div>
                <div className="sliderbox_root_card">
                  <img className="sliderbox_root_card_img" src={img3} alt="輪播圖片"/>
                </div>
              </Carousel>
              <img src={iphoneFrame} className="sliderbox_frame" alt="手機外框"/>
              <img className="sliderbox_desctiption" src={subLogo} alt="原創ACG"/>
            </div>
          </div>
          <div className="container">
            <div className="descriptionbox">
              <div className="descriptionbox_title">
                <p className="descriptionbox_title_highlight">海量资源</p>
                <p className="descriptionbox_title_text">高速免费看</p>
              </div>
              <div className="descriptionbox_download">
                <div className="descriptionbox_download_qrcode">
                  <img className="descriptionbox_download_qrcode_img" src={qrCode} alt="downloadqrcode"/>
                </div>
                <div className="descriptionbox_download_buttonbox">
                  <a
                    href={url}
                    className="descriptionbox_download_buttonbox_button RGB_effect"
                  >
                    <FontAwesomeIcon className="descriptionbox_download_buttonbox_button_icon" icon={faEye} />
                    <span>在线观看</span>
                  </a>
                  {/* <a 
                    href="https://apphost.blob.core.windows.net/apphost/bHub_release_production_v3.3.3_c29_454d5c9.apk"
                    className="descriptionbox_download_buttonbox_button RGB_effect"
                  >
                    <FontAwesomeIcon className="descriptionbox_download_buttonbox_button_icon" icon={faAndroid} />
                    <span>Android版</span>
                  </a> */}
                </div>
              </div>
              <div className="descriptionbox_Remark">
                { nowMediaQuery !== "mobile" ? (
                  <>
                    <p>※电脑用户请扫描二维码观看</p>
                    <p>※勿使用微信、QQ浏览器下载(苹果用户请使用Safari)</p>
                    <p>※看片前请先收藏最新發布地址：</p>
                    <p>{ location.href.split('?')[0]}</p>
                  </>
                ): (
                  <>
                    <p>※安卓旧用户请先卸载旧版本，再下载安装新版本！</p>
                    <p>※勿使用微信、QQ浏览器下载(苹果用户请使用Safari)</p>
                    <p>※看片前请先收藏最新發布地址：{ location.href.split('?')[0]}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </>
    )
  }
}

export default SectionPhoneSlider;