import React from "react";

// import 

import styled from "@emotion/styled";

import potatoImg from "../assets/potato.png";
import telegramImg from "../assets/telegram.png";

const breakpoints = [767, 768]

const mq = breakpoints.map(
  bp => {
    return `@media (${bp === 767 ? 'max' : 'min' }-width: ${bp}px)`
  }
)

const SideNavElement = styled.div`
z-index: 1;
position: fixed;
right: 5vw;
bottom: 20px;
transition: .3s;
.container {
  &_item {
    margin-top: 10px;
    &_icon {
      position: relative;
      display: block;
      border-radius: 50%;
      color: #fff;
      background-image: linear-gradient(
        90deg
        , #FF2B79 10%, #FF6830 90%);
      &_img {
        position: relative;
        width: 36px;
        vertical-align:middle;
      }
      &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        height: 12px;
        border-radius: 50%;
        background-image: linear-gradient(
        90deg
        , #FF2B79 10%, #FF6830 90%);
      }
    }
    &_text {
      display: block;
      padding: 4px;
      width: 36px;
      line-height: 1em;
      font-size: 14px;
      color: #fff;
      background-image: linear-gradient(
        90deg
        , #FF2B79 10%, #FF6830 90%);
        &.moreapp{
          color: #000;
          background-image: linear-gradient(
            90deg
            , #FFF988 10%, #ffeeee 90%);
        }

    }
  }
}
${mq[0]}{
  bottom: 50%;
  transform: translateY(50%);
}
`;

export default function SideNav () {
  return (
    <SideNavElement>
      <div className="container">
        <div className="container_item">
          <a 
            className="container_item_icon"
            href="https://lynnconway.me/BBCAm"
            target="_blank"
            rel="noreferrer"
          >
            <img 
              className="container_item_icon_img"
              src={potatoImg} 
              alt="potato" 
            />
          </a>
        </div>
        <div className="container_item">
          <a 
            className="container_item_icon"
            href="https://t.me/bbllbb_porn"
            target="_blank"
            rel="noreferrer"
          >
            <img 
              className="container_item_icon_img"
              src={telegramImg} 
              alt="telegram"
            />
          </a>
        </div>
        <div className="container_item">
          <a 
            className="container_item_text"
            href="https://mkt.bliav.com"
            target="_blank"
            rel="noreferrer"
          >
            广告合作
          </a>
        </div>
        <div className="container_item">
          <a 
            className="container_item_text moreapp"
            href="https://jingyindao.com/"
            target="_blank"
            rel="noreferrer"
          >
            更多应用
          </a>
        </div>
      </div>
    </SideNavElement>
  )
}