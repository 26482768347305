import React from "react";

import styled from "@emotion/styled";

const DotSideBarItem = styled.div({
  zIndex: '1',
  position: 'fixed',
  right: '5vw',
  top: '50%',
  transform: 'translateY(-50%)',
},
props => ({
  display: props.nowMediaQuery === 'mobile' ? 'none' : 'block',
}));

const DotItem = styled.div({
  cursor: 'pointer',
  width: '30px',
  height: '30px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  margin: '30px 0',
});

export default function DotSideBar(props) {
  const { sectionData, nowScroll, moveContainer, nowMediaQuery } = props;

  const MoveScrollPage = function(toPage) {
    moveContainer(toPage - nowScroll);
  }

  return (
    <DotSideBarItem nowMediaQuery={nowMediaQuery}>
      {sectionData.map((value, index) => {
        return (
          <DotItem 
            key={'dotIndex_' + index}
            style={{
              backgroundColor: nowScroll === index ? '#ff2560' : ''
            }}
            onClick={()=>{MoveScrollPage(index)}}
          />
        )
      })}
    </DotSideBarItem>
  )
}