import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";

// import potatoImg from "./assets/potato.png";
// import telegramImg from "./assets/telegram.png";
// import { useMediaQuery } from "react-responsive";
// import "./App.css?t=3";
// import BigScreenMainPage from "./components/BigScreenMainPage";
// import SmallScreenMainPage from "./components/SmallScreenMainPage";
// 


import styled from "@emotion/styled";

import HeaderBar from './components2/HeaderBar';
// import CoverAds from './components2/CoverAds';
import DotSideBar from './components2/DotSideBar'; 
import SideNav from './components2/SideNav'; 

import SectionPhoneSlider from './components2/SectionPhoneSlider';
import {SectionHighLight, FastItem, HDItem, FreeItem} from './components2/SectionHighLight';
import SectionInstall from './components2/SectionInstall';


import backgroundImage1 from './assets/home.png';
import backgroundImage2 from './assets/home2.png';
import backgroundImage3 from './assets/home3.png';

//

const ReactScrollWheelElement = styled.div`
position: relative;
overflow: hidden;
.wheel_container{
  height: ${({length}) => 100 * length}vh;
  transition: .3s;
}
`;

const SectionItem = styled.section`
  position: relative;
  width: 100%;
  background-image: url(${({backgroundImage})=> backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${({index}) => {
    if(index === 0){
      return '80%';
    }else if(index === 5){
      return '40%';
    }else{
      return 'center center';
    }
  }};
  transition: .3s;
  @media (min-width: 768px){
    background-position: center center;
  }
`;


let sectionData = {
  'mobile': [
    {
      element: SectionPhoneSlider,
      backgroundImage: backgroundImage1
    },
    {
      element: HDItem,
      backgroundImage: backgroundImage2
    },
    {
      element: FastItem,
      backgroundImage: backgroundImage2
    },
    {
      element: FreeItem,
      backgroundImage: backgroundImage2
    },
    {
      element: SectionInstall,
      backgroundImage: backgroundImage3
    },
  ],
  'desktop': [
    {
      element: SectionPhoneSlider,
      backgroundImage: backgroundImage1
    },
    {
      element: SectionHighLight,
      backgroundImage: backgroundImage2
    },
    {
      element: SectionInstall,
      backgroundImage: backgroundImage3
    },
  ]
}
// 防止過度刷新導致lag的處理
let stopResize;
let stopScroll;
let checkScroll = true;


function preventDefault(e) {
  e.preventDefault();
}

var supportsPassive = false;
try {
  window.addEventListener(
    "test",
    null,
    Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true;
        return supportsPassive;
      },
    })
  );
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;

window.addEventListener("touchmove", preventDefault, wheelOpt);

function App() {
  
  const [nowMediaQuery, setNowMediaQuery] = useState('mobile');

  const [nowScroll, setNowScroll] = useState(0);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const wheelElement = useRef(null);

  // 選單資料，統一由這邊新增修改
  const navListData = [
    // {
    //   title: '友站連結',
    //   subList: [
    //     {
    //       title: '秘密入口',
    //       href: 'https://www.mimirukou8.com'
    //     },
    //     {
    //       title: '企鹅导航',
    //       href: 'https://72pro.cc'
    //     },
    //     {
    //       title: '杏MAP导航',
    //       href: 'https://xxmap1.online'
    //     },
    //     {
    //       title: '萌翻导航',
    //       href: 'https://cm365.xyz/faDZYZ'
    //     },
    //     {
    //       title: 'H365',
    //       href: 'https://marketing.h365.site/bliporn/'
    //     },
    //   ]
    // },
    // {
    //   title: '加入官方群',
    //   subList: [
    //     {
    //       title: 'Potato交流群',
    //       icon: potatoImg,
    //       href: 'https://lynnconway.me/BBCAm'
    //     },
    //     {
    //       title: 'Telegram交流群',
    //       icon: telegramImg,
    //       href: 'https://t.me/bbllbb_porn'
    //     },
    //   ]
    // },
    // {
    //   title: '联系客服',
    //   href: 'https://jq.qq.com/?_wv=1027&k=K9RA6FNh'
    // },
    // {
    //   title: '广告合作',
    //   href: 'https://mkt.bliav.com'
    // },
  ]

  const bottomListData = [
    // {
    //   title: '秘密入口',
    //   href: 'https://www.mimirukou8.com'
    // },
    // {
    //   title: '激萌导航',
    //   href: 'https://www.jimengdaohang.best/'
    // },
    // {
    //   title: '福利百科',
    //   href: 'https://baike.dizhi.wiki'
    // },
    // {
    //   title: '成人色导航',
    //   href: 'https://www.crsxx.com/'
    // },
    // {
    //   title: '企鹅导航',
    //   href: 'https://72pro.cc'
    // },
    // {
    //   title: '杏MAP导航',
    //   href: 'https://xxmap1.online'
    // },
    // {
    //   title: '萌翻导航',
    //   href: 'https://cm365.xyz/faDZYZ'
    // },
    // {
    //   title: 'H365',
    //   href: 'https://marketing.h365.site/bliporn/'
    // },
  ]

  // 選單資料

  // 防止過度刷新導致lag的處理
  window.addEventListener('resize', ()=>{
    clearTimeout(stopResize);
    stopResize = setTimeout(() => {
      moveContainer(0);
      setWindowHeight(window.innerHeight);
      if(document.body.clientWidth <= 767){
        setNowMediaQuery('mobile');
      }else{
        setNowMediaQuery('desktop');
      }
    }, 100);
  });


  const upHandler = () => {
    moveContainer(1);
  };
  
  const downHandler = () => {
    moveContainer(-1);
  };

  const moveContainer = (number)=>{
    setNowScroll((nowScroll + number) <= 0 ? 0 : (nowScroll + number >= (sectionData[nowMediaQuery].length - 1) ? (sectionData[nowMediaQuery].length - 1) : nowScroll + number))
  }

  
  // const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  // const isTablet = useMediaQuery({ minWidth: 450, maxWidth: 1224 });
  // const isMobile = useMediaQuery({ maxWidth: 450 });
  

  useEffect(()=>{
    if(document.body.clientWidth <= 767){
      setNowMediaQuery('mobile');
    }else{
      setNowMediaQuery('desktop');
    }
  }, [windowHeight]);

  const handlers = useSwipeable({
    onSwipedUp: (eventData) => {
      upHandler();
    },
    onSwipedDown: (eventData) => {
      downHandler();
    }
  })

  const refPassthrough = (el) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    wheelElement.current = el;
  };

  return (
    <>
      <HeaderBar nowMediaQuery={nowMediaQuery}/>
      {/* <CoverAds nowMediaQuery={nowMediaQuery}/> */}
      <DotSideBar 
        nowScroll={nowScroll}
        moveContainer={moveContainer}
        nowMediaQuery={nowMediaQuery} 
        sectionData={sectionData[nowMediaQuery]}
      />
      <SideNav/>
      <ReactScrollWheelElement 
        {...handlers}
        ref={refPassthrough}
        onWheel={(e)=>{
          if(checkScroll){
            checkScroll = false;
            clearTimeout(stopScroll);
            stopScroll = setTimeout(() => {
              checkScroll = true;
            }, 300);
            if(e.deltaY < 0){
              downHandler();
            }else if(e.deltaY > 0){
              upHandler();
            }
          }
        }}
      >
        <div 
          className="wheel_container"
          style={{
            height: windowHeight + 'px',
            transform: 'translateY(-' + ((nowScroll >= sectionData[nowMediaQuery].length - 1 ? sectionData[nowMediaQuery].length - 1 : nowScroll) * windowHeight) + 'px)'
          }}
        >
          {sectionData[nowMediaQuery].map((value, index)=>{
            return (
              <SectionItem 
                key={'section_' + index}
                index={index}
                backgroundImage={value.backgroundImage}
                className="section"
                style={{
                  height: windowHeight + 'px'
                }}
              >
                <value.element 
                  nowMediaQuery={nowMediaQuery} 
                  navListData={navListData}
                  bottomListData={bottomListData}
                />
              </SectionItem>
            )
          })}
        </div>
      </ReactScrollWheelElement>
      {/*  */}
      {/* {isDesktopOrLaptop && <BigScreenMainPage />}
      {isTablet && <BigScreenMainPage />}
      {isMobile && <SmallScreenMainPage />} */}
    </>
  );
}

export default App;
