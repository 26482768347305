import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';

import {FooterItem, SectionInstallItem, SectionInstallContainer} from "./SectionInstallComponents/SectionInstallElement";

import appleDownloadInstruction from "../assets/iosstep.png";
import androidDownloadInstruction from "../assets/androidstep.png";
import appleDownloadInstructionM from "../assets/iphonestep_m.png";
import androidDownloadInstructionM from "../assets/androidstep_m.png";

class SectionInstall extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

    // 都會執行 用來直接指派Props
    static getDerivedStateFromProps(props = null){
      if(props){
        return props
      }else{
        return null
      }
    }

  render(){
    const { state } = this;
    const { bottomListData, nowMediaQuery } = state;
    return (
      <SectionInstallItem>
        <SectionInstallContainer nowMediaQuery={nowMediaQuery}>
          <div className="titlebox">
            <p className="titlebox_title">安装说明</p>
            <p className="titlebox_description">我们不会放置任何恶意程序，请您放心安装和使用</p>
          </div>
          <input 
            type="radio" 
            name="install_input" 
            value="ios" 
            id="install_show_ios" 
            className="install_show_ios displaynone" 
            defaultChecked
          />
          <input 
            type="radio" 
            name="install_input" 
            value="android" 
            id="install_show_android" 
            className="install_show_android displaynone" 
          />
          <div className="buttonbox">
            <label htmlFor="install_show_ios" className="buttonbox_card">
              <div className="buttonbox_card_header">
                <FontAwesomeIcon className="buttonbox_card_header_icon" icon={faApple} />
              </div>
              <div className="buttonbox_card_body">
                <p className="buttonbox_card_body_title">
                  <span className="buttonbox_card_body_title_text">iOS应用</span><span className="buttonbox_card_body_title_text">添加信任</span>
                </p>
                <p className="buttonbox_card_body_pc_discription">使用B次元进行以下设置，用户体验提升也更方便</p>
              </div>
            </label>
            <label htmlFor="install_show_android" className="buttonbox_card">
              <div className="buttonbox_card_header">
                <FontAwesomeIcon className="buttonbox_card_header_icon" icon={faAndroid} />
              </div>
              <div className="buttonbox_card_body">
                <p className="buttonbox_card_body_title">
                  <span className="buttonbox_card_body_title_text">Android企业级</span><span className="buttonbox_card_body_title_text">应用添加信任</span>
                </p>
                <p className="buttonbox_card_body_pc_discription">因Android手机品牌型号过多，形容词略有不同</p>
              </div>
            </label>
          </div>
          <div className="contrinerbox">
            <div className="contrinerbox_slider">
              <div className="contrinerbox_slider_card">
                <img className="contrinerbox_slider_card_img" src={nowMediaQuery === "mobile" ? appleDownloadInstructionM : appleDownloadInstruction} alt="蘋果安裝說明"/>
              </div>
              <div className="contrinerbox_slider_card">
                <img className="contrinerbox_slider_card_img" src={nowMediaQuery === "mobile" ? androidDownloadInstructionM : androidDownloadInstruction} alt="安卓安裝說明"/>
              </div>
            </div>
          </div>
        </SectionInstallContainer>
        <FooterItem>
          <div className="footer_linkbox">
            {bottomListData.map(value=>{
              return <a 
                className="footer_linkbox_link"
                href={value.href}
                key={'link_' + value.title}
                target="_blank"
                rel="noreferrer"
              >{value.title}</a>
            })}
          </div>
          <div className="footer_text">
            &copy; 2021 B次元 all rights reserved
          </div>
        </FooterItem>
      </SectionInstallItem>
    )
  }
}

export default SectionInstall;