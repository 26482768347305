/* eslint-disable no-useless-computed-key*/
import styled from "@emotion/styled";

const breakpoints = [767, 768]

const mq = breakpoints.map(
  bp => {
    return `@media (${bp === 767 ? 'max' : 'min' }-width: ${bp}px)`
  }
)


export const HeaderBar = styled.header(
  {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '52px',
    padding: '4px 6.56vw',
    backgroundColor: 'rgba(0,0,0,.75)',
    transition: '.3s',
    ['.header_bar']: {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#fff',
      ['&_logobox']: {
        ['&_logo']: {
          transition: '.3s',
          width: '126px',
          verticalAlign: 'middle',
        }
      },
      ['&_listbox']: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        ['&_hamburger']: {
          zIndex: '10',
          position: 'relative',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          transition: '.3s',
          ['&_line']: {
            display: 'block',
            ['&, &::before, &::after']: {
              width: '100%',
              height: 0,
              borderBottom: '4px solid #fff',
              borderRadius: '2px',
              transition: 'transform .3s 0s, border .1s .0s, all .1s .3s',
            },
            ['&::before, &::after']: {
              content: '""',
              position: 'absolute',
              width: '100%',
              transformOrigin: 'left',
            },
            ['&::before']: {
              transform: 'translateY(-10px)',
            },
            ['&::after']: {
              transform: 'translateY(10px)',
            },
          }
        },
        ['&_list']: {
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgba(0,0,0,.75)',
          transform: 'translateX(100%)',
          transition: '.3s',
          overflow: 'auto',
          ['&_item']: {
            display: 'block',
            // ['&:first-of-type']: {
            //   order: 1
            // },
            ['&_link']: {
              display: 'block',
              padding: '5px 10px',
              margin: '2px 0',
              ['&_img']:{
                display: 'none',
              },
              ['&_more']: {
                display: 'none',
              },
              ['&_text']: {
                backgroundColor: '#fff'
              }
            },
            ['.header_bar_listbox_list']: {
              position: "unset",
              transform: "unset",
              backgroundColor: "unset",
              paddingTop: "unset",
              ['&_item']: {
                ['&:first-of-type']: {
                  order: 0
                },
              }
            }
          }
        }
      }
    },
    [mq[0]]: {
      ['.header_bar']: {
        ['&_listbox']: {
          ['& > .header_bar_listbox_list']: {
            height: 'calc(100vh - 52px)',
            marginTop: '52px',
            // ['&> .header_bar_listbox_list_item:first-of-type']: {
            //   ['.header_bar_listbox_list_item_link']:{
            //     fontSize: '0.8em'
            //   }
            // }
          },
          ['&_hamburgercheckbox']: {
            ['&:checked + .header_bar_listbox_hamburger']: {
              transform: 'rotate(90deg)',
              padding: '12px 0 0',
              ['.header_bar_listbox_hamburger_line']:{
                // transform: 'translateY(-12px) rotate(90deg)',
                borderRadius: '0 0 2px 2px',
                ['&::before, &::after']: {
                  width: '52%',
                  height: '50%',
                  border: '3px solid #fff',
                  borderBottom: '0px',
                  borderRadius: '50% 50% 0 0'
                },
                ['&::before']: {
                  transform: 'translateY(-100%)',
                },
                ['&::after']: {
                  transform: 'translateY(-100%) translateX(90%)',
                },
              }
            },
            ['&:checked ~']: {
              ['.header_bar_listbox_hamburgerall']: {
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              },
              ['.header_bar_listbox_list']: {
                transform: 'translateX(0)',
              }
            }
          },
        }
      }
    },
    [mq[1]]: {
      height: '80px',
      padding: '10px 6.56vw',
      ['.header_bar']: {
        ['&_logobox']: {
          ['&_logo']: {
            width: '175px',
          }
        },
        ['&_listbox']: {
          ['&_hamburger, &_hamburgerall']: {
            display: 'none',
          },
          ['&_list']: {
            display: 'block',
            position: 'unset',
            backgroundColor: 'unset',
            transform: 'unset',
            paddingTop: "unset",
            ['&_item']: {
              display: 'inline-block',
              ['&:first-of-type']: {
                order: 0
              },
              ['&_link']: {
                color: "transparent",
                padding: '5px 18px',
                marginLeft: '17px',
                backgroundColor: '#aaaaaa55',
                border: '1px solid #ccc',
                borderRadius: '50px',
                ['&_img']:{
                  width: '25px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                },
                ['&_more']: {
                  display: 'block',
                },
                ['&:hover']:{
                  ['& + .header_bar_listbox_list']: {
                    transform: 'translateX(0%)',
                    transition: '0.3s'
                  }
                },
              },
              ['.header_bar_listbox_list']: {
                minWidth: '29vw',
                position: 'absolute',
                top: '100%',
                right: '0',
                bottom: 'unset',
                padding: '10px',
                backgroundColor: 'rgba(0,0,0,.75)',
                transform: 'translateX(100%)',
                transition: 'all 0.3s .3s',
                ['&:hover']:{
                  transform: 'translateX(0%)',
                  transition: '0.3s'
                }
              }
            },
          }
        }
      }
    }
  },
);

export const Container = styled.div({
width: '100%',
height: '100%',
display: 'flex',
justifyContent: 'center',
alignItems: 'center',
flexDirection: 'column',
['.container']: {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: 'auto',
  ['&:first-of-type']:{
  }
},
['.sliderbox']: {
  position: 'relative',
  width: '49%',
  maxWidth: '280px',
  ['&_root']: {
    padding: '4.5%',
    ['&_card']: {
      ['&_img']: {
      }
    }
  },
  ['&_frame']: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  ['&_desctiption']: {
    position: 'absolute',
    left: '-38%',
    bottom: '5%',
    width: '82%'
  }
},
['.descriptionbox']: {
  justifySelf: "flex-end",
  ['&_title']:{
    display: 'none',
  },
  ['&_download']:{
    ['&_qrcode']:{
      display: 'none',
    },
    ['&_buttonbox']:{
      display: 'flex',
      justifyContent: 'space-evenly',
      margin: '0 0 3vh',
      ['&_button']: {
        padding: '4px 10px',
        border: '2px solid #fff',
        borderRadius: '10px',
        boxShadow: '0px 0px 3px #000000aa',
        color: '#fff',
        backgroundImage: 'linear-gradient(90deg, #FF2B79 10%, #FF6830 90%)',
        ['&_icon']: {
          width: '1em',
          fontSize: '1.1em',
          verticalAlign: 'middle',
          marginRight: '0.5em',
        },
        ['& > span']: {
          verticalAlign: 'middle',
          backgroundColor: '#fff'
        }
      }
    },
  },
  ['&_Remark']:{
    padding: '4px',
    fontSize: '12px',
    backgroundColor: '#ffffff5c;',
    borderRadius: '4px 4px 0 0'
  },
},
[mq[1]]: {
  flexDirection: 'row',
  ['.container']: {
    width: '50%',
    padding: '40px',
    justifyContent: 'flex-start',
    marginTop: 'unset',
    ['&:first-of-type']:{
      paddingTop: '12vh',
      justifyContent: 'flex-end',
    }
  },
  ['.sliderbox']: {
    maxWidth: '375px',
    ['&_frame']: {
    },
    ['&_desctiption']: {
      left: '-82%'
    }
  },
  ['.descriptionbox']: {
    ['&_title']:{
      display: 'block',
      ['&_highlight']: {
        color: '#ff2560',
      },
      ['&_text']: {
        color: '#fff'
      },
      ['&_text, &_highlight']: {
        fontSize: '2vw',
        letterSpacing: '5px',
        filter:   `drop-shadow(1px 0px 0px #000)
                  drop-shadow(-1px 0px 0px #000)
                  drop-shadow(0px 1px 0px #000)
                  drop-shadow(0px -1px 0px #000)
                  drop-shadow(2px 0px 0px #fff)
                  drop-shadow(-2px 0px 0px #fff)
                  drop-shadow(0px 2px 0px #fff)
                  drop-shadow(0px -2px 0px #fff)`,
      },
    },
    ['&_download']:{
      display: 'flex',
      paddingTop: '30px',
      ['&_qrcode']:{
        display: 'inline-block',
        width: '30%',
        ['&_img']:{
          width: '100%'
        }
      },
      ['&_buttonbox']:{
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
        width: '60%',
        paddingLeft: '30px',
        margin: 'unset',
        ['&_button']: {
          width: '100%',
          maxWidth: '200px',
          padding: '2% 4%',
          ['&_icon']: {
            fontSize: '2em'
          }
        }
      },
    },
    ['&_Remark']:{
      fontSize: '16px',
      paddingTop: '30px',
      backgroundColor: 'transparent'
    },
  },
}
});