import React from "react";

import {ImageBox, ImageItem, SectionHighLightItem} from "./SectionHighLightComponents/SectionHighLightElement";

import bgTwoMidPhone from "../assets/mid_pc.png?t=20210401";
import bgTwoRightPhone from "../assets/right_pc.png?t=20210401";
import bgTwoLeftPhone from "../assets/left_pc.png?t=20210401";

const FastItem = function (){
  return (
    <ImageBox>
      <ImageItem src={bgTwoLeftPhone} alt="Fast"/>
    </ImageBox>
  )
}
const FreeItem = function (){
  return (
    <ImageBox>
      <ImageItem src={bgTwoRightPhone} alt="free"/>
    </ImageBox>
  )
}
const HDItem = function (){
  return (
    <ImageBox>
      <ImageItem src={bgTwoMidPhone} alt="HD"/>
    </ImageBox>
  )
}

class SectionHighLight extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render(){
    return (
      <SectionHighLightItem>
        <FastItem />
        <HDItem />
        <FreeItem />
      </SectionHighLightItem>
    )
  }
}


export {
  SectionHighLight,
  FastItem,
  HDItem,
  FreeItem
};