import React, { useRef, useEffect, useState } from "react";

import header from "../assets/header-t.png";
import headerM from "../assets/header-m.png";
import doll from "../assets/doll.png";
import showWord from "../assets/show-word.png";

import styled from "@emotion/styled";

const HeaderBarItem = styled.header`

z-index: 10;
position: fixed;
top: 0;
left: 0;
right: 0;

.nav_bar{
  position: relative;
  width: 100%;

  &_content {
    position: absolute;
    left: -5%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    &_person {
      height: 100%;
      &_img {
        height: 100%;
        vertical-align: middle;
      }
    }
  
    &_count {
      width: 24vw;
      margin-top: -5%;
      margin-left: -13%;
      &_text {
        &_img {
          width: 60%;
          vertical-align: middle;
        }
      }
      &_count {
        color: #0ff;
        font-family: 'Black Ops One', cursive;
        filter: 
          drop-shadow(1px 1px 0px #2A2A89) 
          drop-shadow(-1px 1px 0px #2A2A89)
          drop-shadow(-1px -1px 0px #2A2A89)
          drop-shadow(1px -1px 0px #2A2A89)
          drop-shadow(1px 1px 0px #fff)
          drop-shadow(-1px 1px 0px #fff)
          drop-shadow(-1px -1px 0px #fff)
          drop-shadow(1px -1px 0px #fff)
          drop-shadow(1px 1px 0px #2A2A89) 
          drop-shadow(-1px 1px 0px #2A2A89)
          drop-shadow(-1px -1px 0px #2A2A89)
          drop-shadow(1px -1px 0px #2A2A89)
        ;
        transform: rotate(-5deg);
        font-size: 5vw;
        line-height: 5vw;
      }
    }
  }

  &_img{
    width: 100%;
    vertical-align: middle;
  }

  &_close{
    cursor: pointer;
    position: absolute;
    right: 5vw;
    top: 50%;
    transform: translateY(-50%);
    width: 10vw;
    max-width: 44px;
    height: 10vw;
    max-height: 44px;
    border-radius: 50%;
    border: 2px solid #fff;

    &::before, &::after{
      content: '';
      position: absolute;
      width: 60%;
      height: 4px;
      background-color: #fff;
      top: 50%;
      left: 50%;
    }

    &::before{
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after{
      transform: translate(-50%, -50%) rotate(-45deg);
    }

  }
}
@media (min-width: 767px){
  .nav_bar{

    &_content {
      left: 5%;
      
      &_count {
        width: 11vw;
        margin-left: -13%;
        &_count {
          font-size: 3.1vw;
          line-height: 2.8vw;
        }

      }

    }

  }
}
`;

export default function HeaderBar({nowMediaQuery}) {
  const headerElement = useRef(null);
  const [time, setTime] = useState({
    hour: '00',
    min: '00',
    sec: '00',
  });

  const closeBar = function() {
    headerElement.current.style.display="none";
  }

  const conversionTime = function (number) {
    number = Math.floor(number);
    return (String(number).length === 1 ? '0' : '') + number
  }

  useEffect(()=>{
    let unitUnix = [1000, 60, 60, 24];
    let initialTimeUnix = localStorage.getItem('inTime');
    if(!initialTimeUnix){
      initialTimeUnix = Date.now() + (unitUnix[0] * unitUnix[1] * unitUnix[2] * 5 + unitUnix[0] * unitUnix[1] * 32 + unitUnix[0] *48);
      localStorage.setItem('inTime', initialTimeUnix);
    }
    setInterval(() => {
      let time = initialTimeUnix - Date.now();
      if(time <= 0) time = 0;
      let hour = (time / unitUnix[0] / unitUnix[1] / unitUnix[2]) % unitUnix[3];
      let min = (time / unitUnix[0] / unitUnix[1]) % unitUnix[2];
      let sec = (time / unitUnix[0]) % unitUnix[1];
      setTime({
        hour: conversionTime(hour),
        min: conversionTime(min),
        sec : conversionTime(sec),
      })
    }, 1000);
      // eslint-disable-next-line
      // const countdown = new FlipDown(Date.now() / 1000 + ( 60 * 60 * 24 + 60), 'countTimer', {
      //   headings: ["日", "时", "分", "秒"],
      // }).start().ifEnded(() => {
      //   console.log("The countdown has ended!");
      // });;
  }, [])

  return (
    <HeaderBarItem className="header_bar" ref={headerElement}>
      <nav className="nav_bar">
        <div className="nav_bar_content">
          <div className="nav_bar_content_person">
              <img className="nav_bar_content_person_img" src={doll} alt="doll"/>
          </div>
          <div className="nav_bar_content_count">
            <div className="nav_bar_content_count_text">
              <img className="nav_bar_content_count_text_img" src={showWord} alt="活動倒數"/>
            </div>
            <div className="nav_bar_content_count_count" id="countTimer">
              {time.hour}:{time.min}:{time.sec}
            </div>
          </div>
        </div>
        <img className="nav_bar_img" src={nowMediaQuery === 'desktop' ? header : headerM} alt="B次元"/>
        <div className="nav_bar_close" onClick={closeBar}/>
      </nav>
    </HeaderBarItem>
  );
}