/* eslint-disable no-useless-computed-key*/

import styled from "@emotion/styled";

const breakpoints = [767, 768]

const mq = breakpoints.map(
  bp => {
    return `@media (${bp === 767 ? 'max' : 'min' }-width: ${bp}px)`
  }
)

export const FooterItem = styled.footer({
  textAlign: 'center',
  width: '100%',
  fontSize: '14px',
  fontWeight: '900',
  color: '#666',
  backgroundColor: '#e6e6e6',
  flexShrink: 0,
  ['.footer']:{
    ['&_text, &_linkbox']: {
    },
    ['&_text']: {
      marginTop: '5px',
      marginBottom: '5px'
    },
    ['&_linkbox']:{
      marginTop: '5px',
      ['&_link']:{
        padding: '0 5px',
        borderRight: '1px solid #666',
        whiteSpace: 'nowrap',
        ['&:hover']: {
          color: '#f24c7c'
        },  
        ['&:last-of-type']:{
          borderRight: 'none'
        }
      }
    }
  }
});

export const SectionInstallItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

export const SectionInstallContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  ['.titlebox, .buttonbox']: {
    flexShrink: 0
  },
  ['.titlebox']: {
    marginTop: '10vh',
    ['&_title, &_description']: {
      textAlign: 'center',
    },
    ['&_title']: {
      fontSize: '30px',
    },
    ['&_description']: {
      fontSize: '13px'
    },
  },
  ['.buttonbox']: {
    display: 'flex',
    width: '86%',
    padding: '8px 5px',
    margin: '20px auto 0',
    ['&_card']: {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      padding: '8px 4px',
      backgroundColor: '#00000014',
      cursor: 'pointer',
      ['&_header']: {
        padding: '8px',
        borderRadius: '50%',
        backgroundColor: '#FF2560',
        ['&_icon']: {
          width: '1em',
          fontSize: '2em',
          color: '#fff',
        }
      },
      ['&_body']: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '8px',
        ['&_title']: {
          ['&_text']: {
            display: 'block',
            fontSize: '13px'
          }
        },
        ['&_pc_discription']: {
          display: 'none',
        }
      }
    }
  },
  ['.contrinerbox']: {
    height: 0,
    flexGrow: 1,
    ['&_slider']:{
      display: 'flex',
      width: '200%',
      height: '100%',
      transition: '.3s',
      ['&_card']: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        ['&_img']: {
          maxWidth: '90%',
          maxHeight: '90%',
          // objectFit: 'contain',
        }
      }
    }
  },
  // 手機按鈕滑動事件
  [mq[0]]:{
    ['.install_show']: {
      ['&_ios:checked ~ ']:{
        ['.buttonbox']: {
          ['.buttonbox_card:nth-of-type(1)']:{
            outline: '1px solid #f00'
          }
        }
      },
      ['&_android:checked ~ ']:{
        ['.buttonbox']: {
          ['.buttonbox_card:nth-of-type(2)']:{
            outline: '1px solid #f00'
          }
        },
        ['.contrinerbox']: {
          ['.contrinerbox_slider']: {
            transform: 'translateX(-50%)',
          }
        }
      }
    }
  },
  [mq[1]]:{
    ['.titlebox']: {
      ['&_title']: {
        fontSize: '2.3vw',
      },
      ['&_description']: {
        fontSize: '1.8vw',
        paddingTop: '5px'
      },
    },
    ['.contrinerbox']: {
      width: '86%',
      margin: 'auto',
      ['&_slider']:{
        width: '100%',
        justifyContent: 'center',
        ['&_card']: {
          width: '50%',
        }
      }
    },
    ['.buttonbox']: {
      justifyContent: 'space-evenly',
      ['&_card']: {
        cursor: 'auto',
        width: '40%',
        padding: '10px 25px',
        ['&_header']: {
          ['&_icon']: {
            width: '1em',
            fontSize: '3em',
            color: '#fff',
          }
        },
        ['&_body']: {
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
          paddingLeft: '2vw',
          ['&_title']: {
            ['&_text']: {
              display: 'inline',
              fontSize: '18px'
            }
          },
          ['&_pc_discription']: {
            display: 'block',
            color: '#898989',
            fontSize: '14px'
          }
        }
      }
    },
  },
});